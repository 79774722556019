@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
:root {
  /* --font-family: 'Manrope', sans-serif; */
  --font-family: 'Roboto', sans-serif;

  --color-navbar-content:#fff;
  --background-navbar:rgba(3, 0, 28, 0.661);
  --box-shadow-navbar:10px 10px 30px 10px rgba(3, 0, 36, 0.623);
  --color-txt-4:#abb7bd;
  --color-txt-1:#fff;
  --color-txt-2:#2ca2dd;
  --color-txt-3:#b2cbd7;
  --color-link-abme:#80c6e8;
  --backgroud-grad: radial-gradient(circle at 100% 44%, rgba(0, 90, 102, 1) 0%, rgba(3, 0, 36, 1) 100%);
  --background-proj-txt:#385b6de0;
  --color-button:#fff;
  --color-icon:#8eacbb;
  --color-icon-hover:rgb(0, 221, 255);
  --color-logo:#fff;
  --color-proj-border:#fff;
  --box-shadow-proj:10px 10px 30px 10px rgba(3, 0, 36, 1);
  --box-shadow-proj-txt:0px 5px 30px 2px #272727c9;
  --color-txt-footer:rgba(71, 249, 255, 0.733);
  --color-txt-footer-link:rgba(48, 221, 186, 0.733);

}