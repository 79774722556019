@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

:root {
  --color-red: #ec1840;
  --color-purple: #7a18ec;
  --color-white: #fff;
  --color-black-1: #111;
  --color-black-2: #222;
  --color-black-3: #444;
  --speed-normal: 0.5s;
  --speed-fast: 0.4s;
}


.ec__ContactMe{
    grid-area: 10/7/11/-6;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    grid-template-rows: 25% 30% 20% 25%;
    z-index: 2;
}



.ec__ContactMe_Title{
    grid-area: 1/2/2/-2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.ec__ContactMe_Title h1{
    color: var(--color-txt-2);
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 54px;
}


.ec__ContactMe_Text{
    grid-area: 2/2/3/-2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.ec__ContactMe_Text p{
    color: var(--color-txt-3);
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 20px;
    text-align: center;
}

.ec__ContactMe_Links{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    grid-area: 3/2/4/-2;
}

.ec__ContactMe_Links svg{
    fill:var(--color-icon);
    cursor: pointer;
    
}
.ec__ContactMe_Links svg:hover{
    fill:var(--color-icon-hover);
}

.ec__ContactMe_Button{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    grid-area: 4/2/5/-2;
}

.ec__ContactMe_Button a {
    position: relative;
    cursor: pointer;
    width: 180px;
    height: 60px;
    margin: 20px;
    line-height: 60px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    color: var(--color-white);
    transition: var(--speed-normal);
    border: 1px solid var(--color-red);
}
.ec__ContactMe_Button a:hover {
    border: 1px solid transparent;
    background: var(--color-red) url(https://i.postimg.cc/wBXGXbWN/pixel.png);
    transition-delay: 0.8s;
    background-size: 180px;
    animation: animate var(--speed-fast) steps(8) forwards;
}

@keyframes animate {
    0% {
        background-position-y: 0;
    }
    100% {
        background-position-y: -480px;
    }
}


@media screen and (max-width : 1800px)
{
    .ec__ContactMe{
        grid-area: 10/6/11/-5;
    }

}

@media screen and (max-width : 1300px)
{
    .ec__ContactMe{
        grid-area: 10/5/11/-4;
    }

}

@media screen and (max-width : 1100px)
{
    .ec__ContactMe{
        grid-area: 10/4/11/-3;
    }

}

@media screen and (max-width : 950px)
{
    .ec__ContactMe{
        grid-area: 10/3/11/-3;
    }

}

@media screen and (max-width:900px){
    .ec__ContactMe{
        grid-template-rows: 15% auto 15% 20%;
    }
}

@media screen and (max-width:600px)
{
    .ec__ContactMe_Title h1{
        font-size: 40px;
        text-align: center;
    }

    
    .ec__ContactMe{
        grid-area: 10/5/11/-5;
        display: grid;
        grid-template-columns: 5% 90% 5%;
        grid-template-rows: 15% auto 15% 20%;
    }
}