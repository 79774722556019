.container_3d{
    height: 150px;
    width: 150px;
    z-index: 2;
}

@media screen and (max-width : 400px ){
    .container_3d{
        transform: scale(0.66666);
    }

}
