.ec__Project_right{
    height:100%;
    width: 100%;
    grid-area: 1/4/-1/-4;
    display: grid;
    grid-template-columns: 30% 10% 15% 15% 30%;
    /* grid-template-rows: 8% 12.5% 3% 35% 3% 15% 3% 12.5% 8%; */
    grid-template-rows: 3% 12.5% 3% 45% 3% 15% 3% 12.5% 3%;
}

.ec__Project_left{
    height:100%;
    width: 100%;
    grid-area: 1/4/-1/-4;
    display: grid;
    grid-template-columns: 30% 15% 15% 10% 30%;
    grid-template-rows: 3% 12.5% 3% 45% 3% 15% 3% 7.5% 3%;
}

.ec__Project_right .ec__Project_Name{
    grid-area: 2/1/3/3;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.ec__Project_right .ec__Project_Name h3{
    padding: 15px;
    color: var(--color-txt-1);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 25px;
}

.ec__Project_left .ec__Project_Name{
    grid-area: 2/-3/3/-1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.ec__Project_left .ec__Project_Name h3{
    padding: 15px;
    color: var(--color-txt-1);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 25px;
}


.ec__Project_right .ec__Project_Description_text{
    grid-area: 4/1/5/4;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

}

.ec__Project_left .ec__Project_Description_text{
    grid-area: 4/-4/5/-1;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

}

.ec__Project_right .ec__Project_Description_text p{
    color: var(--color-txt-3);
    height: fit-content;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 17px;
    background: var(--background-proj-txt);
    margin-left: 10px;
    border-radius: 5px;
    box-shadow: var(--box-shadow-proj-txt);
    padding: 10px;
}

.ec__Project_left .ec__Project_Description_text p{
    color: var(--color-txt-3);
    height: fit-content;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 17px;
    background: var(--background-proj-txt);
    margin-right: 10px;
    border-radius: 5px;
    box-shadow: var(--box-shadow-proj-txt);
    padding: 10px;
}


.ec__Project_right img{
    height:min(378px,auto);
    width: min(97%,675px);
    border-radius: 10px;
    grid-area: 2/-4/9/-1;
    margin:auto;
    z-index: 1;
}


.ec__Project_left img{
    height:min(378px,auto);
    width: min(97%,675px);
    margin:auto;
    border-radius: 10px;
    grid-area: 2/1/9/4;
    z-index: 1;
}

.ec__Project_right .ec__Project_topics{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 15px;
    grid-area: 6/1/7/3;
    flex-wrap: wrap;
    /* align-items: center; */
}

.ec__Project_left .ec__Project_topics{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 15px;
    grid-area: 6/-3/7/-1;
    flex-wrap: wrap;
    /* align-items: center; */
}

.ec__Project_right .ec__Project_icons{
    grid-area: 8/1/9/3;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}

.ec__Project_left .ec__Project_icons{
    grid-area: 8/-3/9/-1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}




.ec__Project_icon{
    fill:var(--color-icon);
    cursor: pointer;
    margin-inline: 20px;
}

.ec__Project_icon:hover{
    fill:var(--color-icon-hover);
}

.ec__Project_topic{
    height: 25px;
    width: auto;
    margin:5px;

}

.ec__Project_topic p{
    color: var(--color-txt-4);
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 14px;
    /* margin: 3px; */
}




.fade {
    animation-name: fade;
    animation-duration: 0.5s;
  }
  
@keyframes fade {
    from {opacity: 0}
    to {opacity: 1}
}

@media screen and (max-width : 1100px)
{

    .ec__Project_right,
    .ec__Project_left{
        grid-template-columns: 10% 80% 10%;
        grid-template-rows: 8% 5% auto auto 8% 10px;
    }

    .ec__Project_right img,
    .ec__Project_left img{
        grid-area: 3/1/4/-1;
        width: 70%;
    }

    .ec__Project_right .ec__Project_Name,
    .ec__Project_left .ec__Project_Name{
        grid-area: 1/1/2/-1;
        display: flex ;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ec__Project_right .ec__Project_Name h3,
    .ec__Project_left .ec__Project_Name h3{
        text-align: center;
    }

    .ec__Project_right .ec__Project_Description_text,
    .ec__Project_left .ec__Project_Description_text{
        grid-area: 4/1/5/-1;
    }

    .ec__Project_left .ec__Project_Description_text p,
    .ec__Project_right .ec__Project_Description_text p{
        margin-right: 5px;
        margin-left: 5px;
    }


    .ec__Project_right .ec__Project_topics,
    .ec__Project_left .ec__Project_topics{
        grid-area: 2/2/3/-2;
        justify-content: center;
        padding-left: 0px;
        padding-right: 0px;
    }

    .ec__Project_right .ec__Project_icons,
    .ec__Project_left .ec__Project_icons{
        grid-area: 5/2/6/-2;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    


}

@media screen and (max-width : 900px){
    .ec__Project_right img,
    .ec__Project_left img{
        grid-area: 3/1/4/-1;
        width: 85%;
    }
}

@media screen and (max-width : 800px){
    .ec__Project_right,
    .ec__Project_left{
        grid-template-columns: 10% 80% 10%;
        grid-template-rows: 8% fit-content(10%) auto auto 8% 10px;
    }


    .ec__Project_left,.ec__Project_right{
        grid-area: 1/1/-1/-1;
        width: 100%;
    }
}

@media screen and (max-width : 500px){
    .ec__Project_right,
    .ec__Project_left{
        grid-template-columns: 10% 80% 10%;
        grid-template-rows: 8% fit-content(10%) auto auto 3px auto 10px 40px 10px;
    }


    .ec__Project_right img,
    .ec__Project_left img{
        grid-area: 3/1/4/-1;
        width: 85%;
    }

    .ec__Project_right .ec__Project_Name,
    .ec__Project_left .ec__Project_Name{
        grid-area: 1/1/2/-1;
        display: flex ;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ec__Project_right .ec__Project_Description_text,
    .ec__Project_left .ec__Project_Description_text{
        grid-area: 4/1/5/-1;
    }

    .ec__Project_left .ec__Project_Description_text p,
    .ec__Project_right .ec__Project_Description_text p{
        margin-right: 5px;
        margin-left: 5px;
        font-size: 15px;
    }


    .ec__Project_right .ec__Project_topics,
    .ec__Project_left .ec__Project_topics{
        grid-area: 2/2/3/-2;
        justify-content: center;
        padding-top: 5px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .ec__Project_right .ec__Project_topics p,
    .ec__Project_left .ec__Project_topics p{
        font-size: 12px;
    }

    .ec__Project_right .ec__Project_icons,
    .ec__Project_left .ec__Project_icons{
        grid-area: 6/2/7/-2;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}