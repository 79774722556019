.ec__Header{
    /* background: rgb(206, 19, 19); */
    border-radius: 5px;
    grid-area: 4 / 7 / 5 / -6;
    display: grid;
    grid-template-columns: 400px auto 300px auto;
    grid-template-rows: 150px 100px;
    z-index: 2;
}


.ec__Header_Intro{
    grid-area: 1/1/2/2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.ec__Header_Content{
    grid-area: 2 / 1 / 3 / -1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}


.ec__Header_Intro_name {
    color: var(--color-txt-1);
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 54px;
    padding-left: 0px;
}

.ec__Header_Intro h4{
    color: var(--color-txt-4);
    font-family: var(--font-family);
    font-weight:400;
    font-size: 24px;
}

.ec__Header_Content h3{
    color: var(--color-txt-2);
    font-family: var(--font-family);
    font-weight:600;
    font-size: 28px;
    /* text-align: justify; */
}

.ec__Header_Content p{
    color: var(--color-txt-3);
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 20px;
    /* text-align: justify; */
}

.ec__Header_3d_obj{
    height: 100%;
    width: 100%;
    grid-area: 1/ -3 / 2 / -2;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

}

@media screen and (max-width : 1800px)
{
    .ec__Header{
        grid-area: 4 / 6 / 5 / -5;
    }

}


@media screen and (max-width : 1300px)
{
    .ec__Header{
        grid-area: 4/5/5/-4;
    }

}

@media screen and (max-width : 1100px)
{
    .ec__Header{
        grid-area: 4/4/5/-3;
    }

}

@media screen and (max-width : 950px ){
    .ec__Header{
        grid-area: 4/3/5/-3;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 150px 150px auto;
    }

    .ec__Header_Intro{
        grid-area: 2/1/3/-1;
    }

    .ec__Header_Content{
        grid-area: 3/1/4/-1;
    }

    .ec__Header_3d_obj{
        grid-area: 1/1/2/2;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
}
@media screen and (max-width : 400px ){
    .ec__Header{
        grid-area: 4/3/5/-3;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 100px 20px 150px auto;
    }

    .ec__Header_Intro{
        grid-area: 3/1/4/-1;
    }

    .ec__Header_Content{
        grid-area: 4/1/5/-1;
    }

    .ec__Header_3d_obj{
        grid-area: 1/1/2/-1;
    }
}
