.ec__resumeMenu_blur{
    grid-area: 1 / 1 / -1 / -1;
    width: 100%;
    height: 100%;
    z-index: 6;
    -webkit-backdrop-filter: blur(2px); /* assure la compatibilité avec safari */
    backdrop-filter: blur(5px);
    background-color: rgba(182, 182, 182, 0.2);
    display: flex;
}

.ec__resumeMenu_menu{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    height: 200px;
    background-color: rgb(3, 0, 28);
    border: 4px solid #afafaf;
    border-radius: 20px;
    z-index: 7;
    
}

.ec__resumeMenu_resume{
    width: 220px;
    margin-inline: auto;
    height: 150px;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 5px;
}

.ec__resumeMenu_fr{
    /* background-color: blue; */
    width:180px;
    height: 50px;

}

.ec__resumeMenu_en{
    /* background-color: blue; */
    width:180px;
    height: 50px;
    
}

.ec__resumeMenu_close{
    margin-top:5px;
    margin-left: 5px;
    cursor: pointer;
}

.ec__resumeMenu_resume a{
    background-color: rgba(0, 90, 102, 1);
    border-radius: 5px;
    border:  2px solid #afafaf;
    display: flex;
    cursor: pointer;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    color: var(--color-txt-3);
}