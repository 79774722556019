.ec__blur{
    grid-area: 1 / 1 / -1 / -1;
    width: 100%;
    height: 100%;
    z-index: 3;
    -webkit-backdrop-filter: blur(2px); /* assure la compatibilité avec safari */
    backdrop-filter: blur(2px);
    background-color: rgba(182, 182, 182, 0.2);
    display: none;
}

@media screen and (max-width : 950px)
{
    .ec__blur{
        display: flex;
    }

}