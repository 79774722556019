.ec__navbar{
grid-area: 2 / 1 / 3 /-1;
display :grid;
/* padding: 2rem 6rem; */
grid-template-columns: auto 100px 100px 1500px 100px auto;
grid-template-rows: 100%;
border: 2px ;
/* border-color: var(--color-proj-border); */
/* border-radius: 10px; */
box-shadow: var(--box-shadow-navbar);
background: var(--background-navbar);
}

.ec__navbar-links {
    align-items: center;
    grid-area: 1 / 3 / 2 / -3;
}

.ec__navbar-links_logo{
    grid-area: 1 / 3 / 2 / 4;
    display: flex;
    margin: auto;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}



.ec__navbar-links_container {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items:  center;

}


.ec__navbar-links_container p {
    color: var(--color-navbar-content);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 2rem;
    cursor: pointer;
}


.ec__navbar-menu {
    grid-area: 1 / -3 / 2 / -2;
    margin-left : 1rem;
    display : none;
    position : relative;
    justify-content: center;
    align-items: center;
    caret-color: transparent
}

.ec__navbar-menu svg{
    cursor: pointer;
    z-index: 5;

}

@media screen and (max-width : 1800px)
{
    .ec__navbar{
        grid-template-columns: 100px 100px auto 100px ;
    }
    .ec__navbar-links {
        align-items: center;
        grid-area: 1 / 2 / 2 / -2;
    }
    .ec__navbar-links_logo{
        grid-area: 1 / 2 / 2 / 3;
        display: flex;
        margin: auto;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

}

@media screen and (max-width : 950px)
{
    .ec__navbar-links_container{
        display: none;
    }  
    .ec__navbar-menu{
        display: flex;
    }
    .ec__navbar{
        grid-template-columns: 5.5% 100px auto 100px 5.5% ;
    }
    .ec__navbar-menu svg{
        cursor: pointer;
        z-index: 5;
        
    
    }
}

@media screen and (max-width : 500px)
{
    .ec__navbar-links_container{
        display: none;
    }  
    .ec__navbar-menu{
        display: flex;
    }
    .ec__navbar{
        grid-template-columns: 5.5% 100px auto 100px 5.5% ;
    }

}




/* .ec__navbar-menu_container button{
    padding:0.5rem 1rem;
    color: #fff;
    background: #FF4820 ;
    font-family: var(--font-family);
    font-size : 18px;
    line-height : 25px;
    font-weight : 500;
    border-radius: 5px;
    border : none;
    outline: none;
    cursor: pointer;
} */


/* .ec__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 250px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
    z-index: 10;
} */

/* .ec__navbar-blur{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: rgba(182, 182, 182, 0.2);

} */

/* .ec__navbar-menu_container p {
    margin: 1rem 0;
} */

